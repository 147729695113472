import { useRef, useEffect } from "react";
import Globe from "react-globe.gl";
import * as THREE from "three";

const World = () => {
  const globeEl = useRef();

  useEffect(() => {
    const globe = globeEl.current;

    globe.pointOfView({ lat: 30, lng: 20 });
    globe.controls().autoRotate = true;
    globe.controls().autoRotateSpeed = 0.35;

    const CLOUDS_IMG_URL = "./images/clouds.png";
    const CLOUDS_ALT = 0.004;
    const CLOUDS_ROTATION_SPEED = -0.006;

    new THREE.TextureLoader().load(CLOUDS_IMG_URL, (cloudsTexture) => {
      const clouds = new THREE.Mesh(
        new THREE.SphereGeometry(
          globe.getGlobeRadius() * (1 + CLOUDS_ALT),
          75,
          75
        ),
        new THREE.MeshPhongMaterial({ map: cloudsTexture, transparent: true })
      );
      globe.scene().add(clouds);

      (function rotateClouds() {
        clouds.rotation.y += (CLOUDS_ROTATION_SPEED * Math.PI) / 180;
        requestAnimationFrame(rotateClouds);
      })();
    });
  }, []);

  return (
    <Globe
      ref={globeEl}
      animateIn={false}
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
      bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
    />
  );
};

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const isKite = !!urlParams.get("hello");

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col z-10 items-center">
        <img className="mt-20" src="/images/logo.png" alt="logo" width={128} />
        <div className="m-5 text-xl">Boutique browser for news lovers.</div>
        {isKite ? (
          <div className="text-center my-5 text-xl">
            Swipe ⬅️ or ➡️ to go to site.
            <br />
            Swipe ⬇️ at top to search for site.
            <br />
            Swipe ⬇️ at bottom to share or save site.
          </div>
        ) : (
          <a
            href="https://apps.apple.com/us/app/kite/id708299909?itsct=apps_box_badge&amp;itscg=30200"
            style={{
              display: "inline-block",
              overflow: "hidden",
              borderRadius: 13,
              width: 250,
              height: 83,
            }}
          >
            <img
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1401148800"
              alt="Download on the App Store"
              style={{ borderRadius: 13, width: 250, height: 83 }}
            />
          </a>
        )}
      </div>
      <div className="-mt-20">
        <World />
      </div>
    </div>
  );
}

export default App;
